@import '../../../styles/variables';

.hintMessage{
    color: color(rose);
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
    margin: 4px 0;

    >span{
        text-decoration: underline;
    }
}

.hintMessage-hidden{
    opacity: 0;
    transform: translateY(20px);
}

.hintMessage-error{
    color: color(error);
    
    span{
        cursor: pointer;
        text-decoration: underline;
    }
}

.hintMessage-success{
    color: color(success);

    span{
        cursor: pointer;
        text-decoration: underline;
    }
}