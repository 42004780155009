@import '../../../styles/variables';

    
.authLayout{
    background-color: color(charcoal);
    color: white;
    min-height: 100vh;
    display: flex;
    width: 100%;
    padding-top: 20vh;
    
    @include mobile{
        padding-top: 10vh;
    }
    
    .authLayoutInnerContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .childrenContainer{
            padding: var(--mainSectionPadding);
            padding-top: 0;
            padding-bottom: 0;

            @include mobile{
                width: 100%;
            }
        }
        
        .titleSection{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 112px;

            @include mobile{
                margin: 40px 0 60px 0;
            }
            
            h1{
                margin: 0 30px;
                white-space: nowrap;
                font-weight: 400;
                text-align: center;

                @include mobile{
                    white-space: initial;
                }
            }
            
            .divider{
                width: 100%;
                border: $mainBorder;
                height: 1px;
            }
        }
    }

    button{
        max-width: $authInputs;
    }
}