@import './variables';

.login{
            
    .inputsSection{
        display: flex;
        flex-direction: column;
        align-items: center;

        .inputs{
            width: $authInputs;

            @include mobile{
                width: 100%;
            }

            >div{
                margin-bottom: 15px;

            }
            >div:last-child{
                margin-bottom: 0;
            }

            .hint{
                text-align: center;
                width: 100%;
            }

        }

        >div{
            margin-bottom: 10px;
        }

        .alternativeLinks{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 48px;

            >div{
                font-size: 14px;
                border-bottom: $mainBorder;
                width: fit-content;
                cursor: pointer;
                margin-bottom: 32px;
                transition: .2s;
            }

            >div:last-child{
                margin-bottom: 0;
            }
            
            >div:hover{
                color: color(rose);
                border-color: color(rose);
            }
        }

    }
}