@import '../../../styles/variables';

.input{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    border: $mainBorder;
    cursor: text;
    overflow: hidden;
    border-radius: 4px;

    .input__field{
        width: 100%;
        background-color: transparent;
        outline: none;
        font-family: Poppins;
        font-size: 1rem;
        z-index: 1;
        border: none;
        padding-left: 5px;
        transform: translateY(5px);
    }

    .input__placeholder{
        position: absolute;
        padding: 0 5px;
        font-size: 1rem;
        transition: .1s;
        z-index: 0;
        transform-origin: left;
        white-space: nowrap;
    }

    .input__placeholder-focused{
        transform: translateY(-12px) scale(0.6);
        opacity: .5;
        z-index: 2;
    }

}

.input input, .input textarea{
    color: white;
}

.input.input__field-white:hover{
    border-color: color(rose);
}

.input.input-invalid.input__field-white:hover{
    border-color: color(errorDark);
}

.input.input__field-white{
    border-color: white;
}
.input.input__field-white::placeholder {
    color: rgba(255,255,255, .3);
}

.input.input__field-rose{
    border-color: color(rose);
}

.input.input__field-rose input, .input.input__field-rose textarea{
    color: color(rose);
}

.input.input__field-charcoal{
    border-color: color(charcoal);
}

.input.input__field-charcoal input, .input.input__field-charcoal textarea{
    color: color(charcoal);
}

.input.input__field-rose .input__placeholder{
    color: color(rose);
}

.input.input__field-v2{
    color: white;
}

.input.input__field-v2.input-valid{
    background-color: rgba(255,255,255,0.2);
    border-color: transparent;
}

.input.input-invalid.input__field-v2, .input.input-invalid.input__field-v2:focus, .input.input-invalid.input__field-v2.input-focus{
    border: 1px solid white;
}

.input.input-focus{
    border-color: color(rose);
}

.input-disabled{
    opacity: .3;
}

.input-hideLabel{
    .input__field{
        transform: translateY(0)!important;
    }
}

.input-textarea{
    textarea{
        height: 150px;
        resize: none;
    }

    .input__placeholder{
        top: 15px;
    }
}

.input.input-invalid{
    border: 1px solid color(error);
}

.input.input-focus.input-invalid{
    border: 1px solid color(errorDark);
}